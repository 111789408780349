import { ReservaServiceService } from '../reserva-service.service';
import { Component, OnInit } from '@angular/core';
import { Reserva } from '../classes/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reserva-cadastro',
  templateUrl: './reserva-cadastro.component.html',
  styleUrls: ['./reserva-cadastro.component.css']
})
export class ReservaCadastroComponent implements OnInit {


  
  dados = new Reserva();
  
  areas = {
  areaParaReserva : [
    { label: 'Campo de Futebol', value: 'Campo de Futebol' },
    { label: 'Salão de Festas', value:  'Salão de Festas'},
  ]
} ; 

  constructor(
    private reservaServiceService: ReservaServiceService,
    private route : ActivatedRoute) { 

      //this.reserva.data_hora_reserva =  new Date();
    }

  async ngOnInit(): Promise<void> {
    
    if (this.route.snapshot.params['id']){
     
        await this.carregarRegistro(this.route.snapshot.params['id']);
        this.dados.dataHoraReserva = new Date(this.dados.dataHoraReserva);
    }

  }
  
  salvar() {   
    console.log(this.route.snapshot.params['id'] >0);
    if (this.route.snapshot.params['id'] >0){
      
      
      this.reservaServiceService.atualizar(this.dados)
      .then(()=> {

       this.dados = new Reserva();
       console.log()
      })
    }
    else{ 


   this.reservaServiceService.adicionar(this.dados)   
   .then(()=> {
    this.dados = new Reserva();
   })
  }

}
  

   async carregarRegistro(id:string){
    
    setTimeout(async () => {
      await this.reservaServiceService.buscarPorId(id)
    .then( reserva => {
      
      this.dados = reserva;
      this.dados.dataHoraReserva = new Date(this.dados.dataHoraReserva);
      console.log('Data ' + this.dados.dataHoraReserva);
    })
      
    });
  }

  get editando() {
    return Boolean(this.dados.id);
  }

 

 

 
  
  
}
