import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioSistema } from './classes/model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioSistemaServiceService {
  ultimoId = 1;
  dados: any = []

  apiUrl = 'https://bmr-api-devlopment.up.railway.app/usuariossistema';

  constructor( private http: HttpClient) { }

  
    async adicionar(dados: UsuarioSistema): Promise<any> {
  

    
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', dados);
      const response = await this.http.post(this.apiUrl, dados, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };

    console.log(JSON.stringify(dados));
    this.dados.push(dados);
    this.pesquisar();
    console.log(JSON.stringify(this.dados));
  }

  async pesquisar(): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl).toPromise();
      console.log(response);
      

      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }


  async pesquisarPorNome( nome: string): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl+'/nome/'+nome).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }

  async excluirPorId( id: string): Promise<any> {
    try {
      const response = await this.http.delete(this.apiUrl+'/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
  }

  async selecionarPorId( id: string): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl+'/'+id).toPromise();
      console.log(response);
      
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
  }


  async atualizar(dados: UsuarioSistema): Promise<any> {
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', dados);
      const response = await this.http.put(this.apiUrl, dados, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };  
  }


  async buscarPorId(id: string):Promise<any>{
    try {
      const response = await this.http.get(this.apiUrl+'/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }




}