<div class="container">

    <form #f="ngForm">

        <div class="grid">
            <div class="col-12">
                <h1>{{editando ? 'Editando ' : 'Novo '}}Reserva</h1>
            </div>

            <div class="col-12 md:col-9 p-fluid">
                <label>Área reservada</label>
                <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                        <p-radioButton name="Areas" value="Campo" [(ngModel)]="dados.area"
                            inputId="area1"></p-radioButton>
                        <label for="area1" class="ml-2">Campo de Futebol</label>
                    </div>
                    <p></p>
                  
                    <div class="flex align-items-center">
                        <p-radioButton name="Areas" value="Salão" [(ngModel)]="dados.area"
                            inputId="area2"></p-radioButton>
                        <label for="area2" class="ml-2">Salão de Festas</label>
                    </div>
                </div>
                
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Nome</label>
                <input pInputText type="text" name="nome" [(ngModel)]="dados.nome" #nome="ngModel">
            </div>
            <p></p>
            <div class="col-12 p-fluid">
                <label>Descrição</label>
                <textarea pInputTextarea rows="3" name="descricao" [(ngModel)]="dados.descricao"
                    #descricao="ngModel"></textarea>
            </div>
            <p></p>
            <div class="col-12">
                <label>Data da Reserva</label>
                <!-- <input type="date"  id="data_hora_reserva" name="data_hora_reserva" [(ngModel)]="dados.data_hora_reserva"  #data_hora_reserva="ngModel" > -->
                <!-- <p-inputMask [(ngModel)]="reserva.data_hora_reserva" mask="99/99/9999"  slotChar="mm/dd/yyyy" #data_hora_reserva="ngModel"></p-inputMask> -->

                <p-calendar dateFormat="dd/mm/yy" name="dataHoraReserva" [showButtonBar]="true"
                    inputId="dataHoraReserva" [(ngModel)]="dados.dataHoraReserva"
                    #dataHoraReserva="ngModel"></p-calendar>
            </div>


            <p></p>

            <div class="col-12">
                <button pButton label="Salvar" (click)="salvar()"></button>
                <!-- <button pButton type="button" label="Novo" class="p-button-info"></button> -->
                <a routerLink="/reservas">Voltar para a pesquisa</a>
            </div>
        </div>

    </form>
</div>