<div class="container">
    <div class="grid">
  
      <div class="col-12">
        <h1>Usuários do Sistema</h1>
      </div>
  
      <div class="col-12 p-fluid">
        <label>Nome</label>
        <input pInputText type="text" #nome>
      </div>
  
      
      <div class="col-12">
        <p></p>
        <button pButton label="Pesquisar" (click)="pesquisarPorNome(nome.value)"></button>
      </div>
    </div>
  
    <div class="grid">
      <div class="col-12">
        <p-table [value]="dados" [paginator]="true" [rows]="10" [responsive]="true"  >
          <ng-template pTemplate="header">
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th class="col-acoes"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dado>
            <tr>
              <td>
                <span class="p-column-title">Código</span>
                {{ dado.id }}
              </td>
  
              <td>
                <span class="p-column-title">Nome</span>
                {{ dado.nome }}
              </td>
 
              <td class="col-acoes">
                <a pButton icon="pi pi-pencil" pTooltip="Editar" tooltipPosition="top" (click)="detalhe(dado.id)"></a>
                <button  pButton icon="pi pi-trash" pTooltip="Excluir" tooltipPosition="top"
                (click)="excluirPorId(dado.id)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p></p>
    <a pButton label="Novo Usuário do Sistema" routerLink="/usuariossistema/novo"></a>
  
  </div>
