<app-navbar></app-navbar>

<router-outlet></router-outlet>




<p-confirmDialog header="Confirmação" icon="pi pi-exclamation-triangle" #confirmacao>
    <p-footer>
        <button type="button" pButton icon="fa-check" label="Sim" (click)="confirmacao.accept()"></button>
        <button type="button" pButton icon="fa-times" label="Não" (click)="confirmacao.reject()"></button>        
    </p-footer>
</p-confirmDialog>