import { UsuarioSistemaServiceService } from './../usuario-sistema-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-usuario-sistema-pesquisa',
  templateUrl: './usuario-sistema-pesquisa.component.html',
  styleUrls: ['./usuario-sistema-pesquisa.component.css']
})
export class UsuarioSistemaPesquisaComponent implements OnInit {

  constructor(private usuarioSistemaServiceService: UsuarioSistemaServiceService,
    private confirmationService: ConfirmationService,
    private route : Router) {
    ; 
    }

  dados: any = []

  ngOnInit() {

    this.usuarioSistemaServiceService.pesquisar()
      .then((response) => this.dados = response);

  }

  pesquisarPorNome(nome: string) {

    if (nome === "") {
      this.usuarioSistemaServiceService.pesquisar()
        .then((response) => this.dados = response);
    }
    else {
      this.usuarioSistemaServiceService.pesquisarPorNome(nome)
        .then((response) => this.dados = response);
    }
  }

  excluirPorId(id: string){

    this.confirmationService.confirm({
      message: 'Tem certeza que você deseja excluir esse registro ?',
      accept: () => {

        this.usuarioSistemaServiceService.excluirPorId(id)
        .then(()=> {this.pesquisarPorNome("")});
      }
    }    
    );
  }

  alterarPorId(id: string){
        this.usuarioSistemaServiceService.excluirPorId(id)
        .then(()=> {this.pesquisarPorNome("")});
  }

  detalhe(id: string){
    this.route.navigate(['/usuariossistema/'+id])
    
}

}

