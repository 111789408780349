<nav class="navbar">

    <div class="container">
      <div class="grid">
        <div class="col-12">
          <a href="javascript:;" class="navbar-toggle" (click)="exibindoMenu = !exibindoMenu"><i class="pi pi-bars"></i></a>
          <label color="white">  Sistema de Gestão do App BMR</label>
        </div>
      </div>
    </div>
  
    <ul class="navbar-menu" [hidden]="!exibindoMenu">
      <li class="navbar-usuario">Usuário</li>
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/convenios">Convênios</a></li>
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/sindicalizados">Sindicalizados</a></li>
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/excluidos">Sindicalizados Excluidos</a></li>      
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/reservas">Reservas</a></li>
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/usuariossistema">Usuários do Sistema</a></li>
      <li class="navbar-menuitem" routerLinkActive="ativo"><a routerLink="/login">Login</a></li>      
    </ul>
    
  </nav>