import { Component, Input, OnInit } from '@angular/core';
import { ConveniosServiceService } from '../convenios-service.service';
import { Convenio } from '../classes/model';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-convenios-cadastro',
  templateUrl: './convenios-cadastro.component.html',
  styleUrls: ['./convenios-cadastro.component.css']
})
export class ConveniosCadastroComponent implements OnInit {


  selectedFile: File;

  statusList = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value:  false},
  ];

  convenio = new Convenio();
  myImageSrc: any;
  

  constructor(
    private conveniosServiceService: ConveniosServiceService,
    private route : ActivatedRoute) { }

  async ngOnInit(): Promise<void> {

    if (this.route.snapshot.params['id']){
        await this.carregarRegistro(this.route.snapshot.params['id']);
    }

    

  }


  
  salvar() {   
    console.log(this.route.snapshot.params['id'] >0);
    if (this.route.snapshot.params['id'] >0){
      this.conveniosServiceService.atualizar(this.convenio)
      .then(()=> {
        this.onUpload();
       this.convenio = new Convenio();
       console.log()
      })
    }
    else{ 


   this.conveniosServiceService.adicionar(this.convenio)   
   .then(()=> {
    this.onUpload();
    this.convenio = new Convenio();
   })
  }

}
  

   async carregarRegistro(id:string){
    
    setTimeout(async () => {
      await this.conveniosServiceService.buscarPorId(id)
    .then( convenio => {
      
      this.convenio = convenio;
      
    })
      
    });
  }

  get editando() {
    return Boolean(this.convenio.id);
  }

  onFileSelected(event: any) {
    console.log(event);
    this.selectedFile = event.target.files[0] as File;

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
    const file = inputElement.files[0];
      const fileName = file.name.split('.').slice(0, -1).join('.'); // Nome do arquivo (sem extensão)
      const fileExtension = file.name.split('.').pop(); // Extensão do arquivo

      this.convenio.foto = 'https://gryphem.com.br/ImagensBMR/' + fileName+'.'+fileExtension;

      
    }
    
  }


  onUpload() {
    if (!this.selectedFile) {
      console.log('Nenhuma foto selecionada.');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile);

   this.conveniosServiceService.enviarImagem(formData);
  }
  
  
  
}
