import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sindicalizado } from './classes/model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioServiceService {

 
  ultimoId = 1;
  dados: any | any[] = [];

  apiUrl = 'https://bmr-api-devlopment.up.railway.app/usuarios';

  constructor( private http: HttpClient) { }

  
    async adicionar(dados: Sindicalizado): Promise<any> {
  

    
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', dados);
      const response = await this.http.post(this.apiUrl, dados, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };

    console.log(JSON.stringify(dados));
    this.dados.push(dados);
    this.pesquisar();
    console.log(JSON.stringify(this.dados));
  }

  async pesquisar(): Promise<any> {
    try {
      //const response = await this.http.get(this.apiUrl).toPromise();
      const response = await this.http.get(this.apiUrl+'/N').toPromise();
      console.log(response);
      

      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }

  async pesquisarExcluidos(): Promise<any> {
    try {
      //const response = await this.http.get(this.apiUrl).toPromise();
      const response = await this.http.get(this.apiUrl+'/S').toPromise();
      console.log(response);
      

      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }


  async pesquisarPorNome( nome: string): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl+'/N/nome/'+nome).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }

  async pesquisarPorNomeExcluidos( nome: string): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl+'/S/nome/'+nome).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }

  async excluirPorId( id: string): Promise<any> {
   
    try {
     
      const response = await this.http.delete(this.apiUrl+'/N/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
    
  }

  async desexcluirPorId( id: string): Promise<any> {
   
    try {
     
      const response = await this.http.delete(this.apiUrl+'/S/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
    
  }


  async selecionarPorId( id: string): Promise<any> {
    try {
      const response = await this.http.get(this.apiUrl+'/'+id).toPromise();
      console.log(response);
      
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
  }


  async atualizar(dados: Sindicalizado): Promise<any> {
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', dados);
      const response = await this.http.put(this.apiUrl, dados, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };  
  }


  async buscarPorId(id: string):Promise<any>{
    try {
      const response = await this.http.get(this.apiUrl+'/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }


  async enviarImagem(formData: FormData){
    const response = await this.http.post('https://bmr-api-devlopment.up.railway.app/usuarios/upload', formData).toPromise();

}

}