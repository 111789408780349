import { Component, OnInit } from '@angular/core';
import { Sindicalizado } from '../classes/model';
import { UsuarioServiceService } from '../usuario-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-valida-carteira',
  templateUrl: './valida-carteira.component.html',
  styleUrls: ['./valida-carteira.component.css']
})
export class ValidaCarteiraComponent implements OnInit {
  timeStamp = (new Date()).getTime();
  public dados = new Sindicalizado();
  
  constructor(private usuarioServiceService: UsuarioServiceService, private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.timeStamp = (new Date()).getTime();
    if (this.route.snapshot.params['id']) {
      console.log( 'id:' + this.route.snapshot.params['id'])
      await this.carregarRegistro(this.route.snapshot.params['id']);
    }
  }

  
  async carregarRegistro(id: string) {
    this.dados =  await this.usuarioServiceService.buscarPorId(id);
    this.dados.foto = this.dados.foto+ '?' + this.timeStamp;
        
  }

}
