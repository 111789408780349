<div class="container">

    <form #f="ngForm">

        <div class="grid">
            <div class="col-12">
                <h1>{{editando ? 'Editando ' : 'Novo '}}Usuário do Sistema</h1>
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Nome</label>
                <input pInputText type="text" name="nome" [(ngModel)]="dados.nome" #nome="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Email</label>
                <input pInputText type="email" name="email" [(ngModel)]="dados.email" #email="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Senha</label>
                <input pInputText type="password" name="senha" [(ngModel)]="dados.senha" #senha="ngModel">
            </div>

           
            <p></p>

            <div class="col-12">
                <button pButton label="Salvar" (click)="salvar()"></button>
                <!-- <button pButton type="button" label="Novo" class="p-button-info"></button> -->
                <a routerLink="/usuariossistema">Voltar para a pesquisa</a>
            </div>
        </div>

    </form>
</div>