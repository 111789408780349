
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { UsuariosPesquisaComponent } from './usuarios-pesquisa/usuarios-pesquisa.component';
import { UsuariosCadastroComponent } from './usuarios-cadastro/usuarios-cadastro.component';
import { ConveniosPesquisaComponent } from './convenios-pesquisa/convenios-pesquisa.component';
import { ConveniosCadastroComponent } from './convenios-cadastro/convenios-cadastro.component';
import { ReservaPesquisaComponent } from './reserva-pesquisa/reserva-pesquisa.component';
import { ReservaCadastroComponent } from './reserva-cadastro/reserva-cadastro.component';
import { LoginComponent } from './login/login.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { NavbarComponent } from './navbar/navbar.component';
import { ConveniosServiceService } from './convenios-service.service';
import { HttpClientModule } from '@angular/common/http';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';

import { UsuarioSistemaPesquisaComponent } from './usuario-sistema-pesquisa/usuario-sistema-pesquisa.component';
import { UsuarioSistemaCadastroComponent } from './usuario-sistema-cadastro/usuario-sistema-cadastro.component';
import { UsuarioAutenticadoService } from './usuario-autenticado.service';
import { ValidaCarteiraComponent } from './valida-carteira/valida-carteira.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UsuariosExcluidosComponent } from './usuarios-excluidos/usuarios-excluidos.component';


const routes: Routes = [

  {path:'convenios', component:ConveniosPesquisaComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'convenios/novo', component:ConveniosCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'convenios/:id', component:ConveniosCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'usuariossistema', component:UsuarioSistemaPesquisaComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'usuariossistema/novo', component:UsuarioSistemaCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'usuariossistema/:id', component:UsuarioSistemaCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'reservas', component:ReservaPesquisaComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'reservas/novo', component:ReservaCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'reservas/:id', component:ReservaCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'sindicalizados', component:UsuariosPesquisaComponent,canActivate: [UsuarioAutenticadoService]},

  {path:'excluidos', component:UsuariosExcluidosComponent,canActivate: [UsuarioAutenticadoService]},
  
  {path:'sindicalizados/novo', component:UsuariosCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'sindicalizados/:id', component:UsuariosCadastroComponent,canActivate: [UsuarioAutenticadoService]},
  {path:'login', component:LoginComponent},
  {path:'validacarteira/:id', component:ValidaCarteiraComponent},
  

]

@NgModule({
  declarations: [
    AppComponent,
    UsuariosPesquisaComponent,
    UsuariosCadastroComponent,
    ConveniosPesquisaComponent,
    ConveniosCadastroComponent,
    ReservaPesquisaComponent,
    ReservaCadastroComponent,
    LoginComponent,
    NavbarComponent,
    UsuarioSistemaPesquisaComponent,
    UsuarioSistemaCadastroComponent,
    ValidaCarteiraComponent,
    UsuariosExcluidosComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    SelectButtonModule,
    TableModule,
    TabViewModule,
    TooltipModule,
    HttpClientModule,
    ConfirmDialogModule,
    RouterModule.forRoot(routes),
    FormsModule,
    InputSwitchModule,
    InputMaskModule,
    RadioButtonModule,
    
    
  ],
  providers: [ConveniosServiceService,
  ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
