import { Convenio } from './classes/model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ConveniosServiceService  {

  ultimoId = 1;
  convenios: any = []

  conveniosUrl = 'https://bmr-api-devlopment.up.railway.app/convenios';

  constructor( private http: HttpClient) { }

  
    async adicionar(convenio: Convenio): Promise<any> {
  

    
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', convenio);
      const response = await this.http.post(this.conveniosUrl, convenio, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };

    console.log(JSON.stringify(convenio));
    this.convenios.push(convenio);
    this.pesquisar();
    console.log(JSON.stringify(this.convenios));
  }

  async pesquisar(): Promise<any> {
    try {
      const response = await this.http.get(this.conveniosUrl).toPromise();
      console.log(response);
      

      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }


  async pesquisarPorNome( nome: string): Promise<any> {
    try {
      const response = await this.http.get(this.conveniosUrl+'/nome/'+nome).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }

  async excluirPorId( id: string): Promise<any> {
    try {
      const response = await this.http.delete(this.conveniosUrl+'/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
  }

  async selecionarPorId( id: string): Promise<any> {
    try {
      const response = await this.http.get(this.conveniosUrl+'/'+id).toPromise();
      console.log(response);
      
      return  response;
    } catch (error) {
      
      console.error('Erro na exclusão:', error);
      throw error;
    }
  }


  async atualizar(convenio: Convenio): Promise<any> {
    
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.error('cadastro:', convenio);
      const response = await this.http.put(this.conveniosUrl, convenio, { headers: headers }).toPromise();
      
    } catch (error) {
      
      console.error('Erro na cadastro:', error);
      throw error;
    };  
  }


  async buscarPorId(id: string):Promise<any>{
    try {
      const response = await this.http.get(this.conveniosUrl+'/'+id).toPromise();
      console.log(response);
      return  response;
    } catch (error) {
      
      console.error('Erro na pesquisa:', error);
      throw error;
    }
  }


  async enviarImagem(formData: FormData){
        const response = await this.http.post('https://bmr-api-devlopment.up.railway.app/usuarios/upload', formData).toPromise();
  
  }

}
