import { Component, OnInit } from '@angular/core';
import { Sindicalizado } from '../classes/model';
import { UsuarioServiceService } from '../usuario-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usuarios-cadastro',
  templateUrl: './usuarios-cadastro.component.html',
  styleUrls: ['./usuarios-cadastro.component.css']
})
export class UsuariosCadastroComponent implements OnInit {

  selectedFile: File;
  dados = new Sindicalizado();

  constructor(private usuarioServiceService: UsuarioServiceService,
    private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {

    if (this.route.snapshot.params['id']) {
      await this.carregarRegistro(this.route.snapshot.params['id']);
      
    }



  }

  get editando() {
    return Boolean(this.dados.id);
  }

  async carregarRegistro(id: string) {

    setTimeout(async () => {
      await this.usuarioServiceService.buscarPorId(id)
        .then(dado => {

          this.dados = dado;
          console.log(this.dados.sindicalizado);
          console.log(this.dados.validado);
        })

    });
  }

  salvar() {
    console.log(this.route.snapshot.params['id'] > 0);
    if (this.route.snapshot.params['id'] > 0) {
      this.usuarioServiceService.atualizar(this.dados)
        .then(() => {
          this.onUpload();
          this.dados = new Sindicalizado();
          
        })
    }
    else {

      this.dados.origemCadastro = 'WEB';
      this.usuarioServiceService.adicionar(this.dados)
        .then(() => {
          this.dados = new Sindicalizado();
        })
    }

  }


  onFileSelected(event: any) {
    console.log(event);
    this.selectedFile = event.target.files[0] as File;

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {

      const file = inputElement.files[0];
      const fileName = file.name.split('.').slice(0, -1).join('.'); // Nome do arquivo (sem extensão)
      const fileExtension = file.name.split('.').pop(); // Extensão do arquivo

      this.dados.foto = inputElement.files[0].name;


      
      console.log(this.dados.foto);
      this.dados.foto = 'https://gryphem.com.br/ImagensBMR/' + fileName+'.'+fileExtension;

      
    }
    
  }

  onUpload() {
    if (!this.selectedFile) {
      console.log('Nenhuma foto selecionada.');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile);

   this.usuarioServiceService.enviarImagem(formData);
  }

  onChangeSindicalizado(event: any) {
    this.dados.sindicalizado = event.target.checked ? 'S' : 'N'; // Atualiza o valor com base no estado do checkbox
  }

  onChangeValidado(event: any) {
    this.dados.validado = event.target.checked ? 'S' : 'N'; // Atualiza o valor com base no estado do checkbox
  }


}
