import { LoginServiceService } from './../login-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

form: any;

  constructor(private loginServiceService: LoginServiceService) { }

  ngOnInit(): void {
    this.loginServiceService.deslogar();
  }

  login(email:string, senha:string) {
    this.loginServiceService.login(email, senha)
    }

    

}
