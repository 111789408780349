import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {



  constructor(private http: HttpClient, private router: Router) { }

  async login(email: string, senha:string) {
        
    this.http.get<number>('https://bmr-api-devlopment.up.railway.app/usuariossistema/login', { params: { email: email.toString(), senha: senha.toString() } }).subscribe(idusuario => {
      if (idusuario > 0) {
        console.log(email);
        localStorage.setItem('usuario',email);
      this.router.navigate(['']);

      }

    });
  }

  deslogar() {
    localStorage.clear();
    this.router.navigate(['login']);
}

logado(): boolean {
  console.log('LocalstoreUsuario'+localStorage.getItem('usuario'));
  return localStorage.getItem('usuario') ? true : false;
}
}
