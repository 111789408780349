import { UsuarioSistemaServiceService } from './../usuario-sistema-service.service';
import { Component, OnInit } from '@angular/core';
import { UsuarioSistema } from '../classes/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usuario-sistema-cadastro',
  templateUrl: './usuario-sistema-cadastro.component.html',
  styleUrls: ['./usuario-sistema-cadastro.component.css']
})
export class UsuarioSistemaCadastroComponent implements OnInit {

  dados = new UsuarioSistema();

  constructor(private usuarioSistemaServiceService: UsuarioSistemaServiceService,
    private route : ActivatedRoute) { }

  async ngOnInit(): Promise<void> {

    if (this.route.snapshot.params['id']){
        await this.carregarRegistro(this.route.snapshot.params['id']);
    }

    

  }

  get editando() {
    return Boolean(this.dados.id);
  }

  async carregarRegistro(id:string){
    
    setTimeout(async () => {
      await this.usuarioSistemaServiceService.buscarPorId(id)
    .then( dado => {
      
      this.dados = dado;
      
    })
      
    });
  }

  salvar() {   
    console.log(this.route.snapshot.params['id'] >0);
    if (this.route.snapshot.params['id'] >0){
      this.usuarioSistemaServiceService.atualizar(this.dados)
      .then(()=> {
        
       this.dados = new UsuarioSistema();
       console.log()
      })
    }
    else{ 


   this.usuarioSistemaServiceService.adicionar(this.dados)   
   .then(()=> {
        this.dados = new UsuarioSistema();
   })
  }

}
}
