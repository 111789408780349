<div class="container">

    <form #f="ngForm">
          

         <div *ngIf="dados.validado =='S'" class="col-12 md:col-9 p-fluid">
            <p></p>
            <label  >Carteira Validada - Usuário Sindicalizado e Ativo</label>
            
        </div>

        <div *ngIf="dados.validado =='N'" class="col-12 md:col-9 p-fluid">
            <p></p>
            
            <label>USÁRIO NÃO ENCONTRADO - CARTEIRA NÃO VALIDADA</label>
        </div>

        <div *ngIf="dados.validado =='S'" class="container">
            <div class="Logo">
                <img   src="https://gryphem.com.br/ImagensBMR/logoCarteira.png" width="350px" height="150px"  alt="BMR"   loading="lazy" />
            </div>

           
            <div class="Foto">
                <img [src]="dados.foto" class="fotoindentidade" loading="lazy">
            </div>
            <div class="Nome">
                <label>Nome</label>
                {{dados.nome}}
            </div>
            <div class="CPF">
                <label>CPF</label>
                {{dados.cpf}}
            </div>
            <div class="RG">
                <label>RG</label>
                {{dados.rg}}
            </div>
            <div class="DataNascimento">
                <label>Data de Nascimento</label>
                {{dados.dataNascimento | date: 'dd/MM/yyyy'}}
            </div>
            <div class="Telefone">
                <label>Telefone</label>
                {{dados.telefone}}
            </div>
          </div>


    </form>
</div>