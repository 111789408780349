<div class="container">
    <div class="grid">
  
      <div class="col-12">
        <h1>Sindicalizados</h1>
      </div>
  
      <div class="col-12 p-fluid">
        <label>Nome</label>
        <input pInputText type="text"  #nome>
      </div>
  
      
      <div class="col-12">
        <p></p>
        <button pButton label="Pesquisar" (click)="pesquisarPorNome(nome.value)"></button>
      </div>
    </div>
  
    <div class="grid">
      <div class="col-12">
        <p-table [value]="dados" [paginator]="true" [rows]="5" [responsive]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <!-- <th>Email</th> -->
              <th>Origem</th>
              <th>Sindicalizado</th>
              <th>Validado</th>              
              <th class="col-acoes"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-usuario>
            <tr>
              <td >
                <span class="p-column-title" >Código</span>
                {{ usuario.id }}
              </td>
              <td>
                <span class="p-column-title">Nome</span>
                {{ usuario.nome }}
              </td>              
  
              <!-- <td>
                <span class="p-column-title">Email</span>
                {{ usuario.email }}
              </td> -->
  
             
              <td >
                <span class="p-column-title">Origem </span>
                {{ usuario.origemCadastro }}
              </td>
              <td >
                <span class="p-column-title">Sindicalizado </span>
                {{ usuario.sindicalizado }}
              </td>
              <td >
                <span class="p-column-title">Validado </span>
                {{ usuario.validado }}
              </td>                            
  
             
  
              <td class="col-acoes">
                <a pButton icon="pi pi-pencil" pTooltip="Editar" tooltipPosition="top" (click)="detalhe(usuario.id)"></a>
                 <button  pButton icon="pi pi-trash" pTooltip="Excluir" tooltipPosition="top"
                (click)="excluirPorId(usuario.id)"></button> 
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p></p>
    <a pButton label="Novo Sindicalizado" routerLink="/sindicalizados/novo"></a>
  
  </div>
