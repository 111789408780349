<div class="container">

    <form #f="ngForm">

        <div class="grid">
            <div class="col-12">
                <h1>{{editando ? 'Editando ' : 'Novo '}}Convênio</h1>
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Nome</label>
                <input pInputText type="text" name="nome" [(ngModel)]="convenio.nome" #nome="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Título</label>
                <input pInputText type="text" name="titulo" [(ngModel)]="convenio.titulo" #titulo="ngModel">
            </div>
            <p></p>
            <div class="col-12 p-fluid">
                <label>Resumo</label>
                <textarea pInputTextarea rows="3" name="resumo" [(ngModel)]="convenio.resumo"
                    #resumo="ngModel"></textarea>
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Imagem do Convênio </label>
                <!-- <input type="file" name="foto" [(ngModel)]="convenio.foto" #foto="ngModel"> -->
                <input type="file" accept=".jpg" name="foto" #foto="ngModel" [(ngModel)]="convenio.foto" (change)="onFileSelected($event)">
            </div>
            <p></p>
            <div class="col-12">
                <button pButton label="Salvar" (click)="salvar()"></button>
                <!-- <button pButton type="button" label="Novo" class="p-button-info"></button> -->
                <a routerLink="/convenios">Voltar para a pesquisa</a>
            </div>
        </div>

    </form>
</div>