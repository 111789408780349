export class Convenio {
    id: number=0;
    nome: string ;
    titulo: string;
    resumo: string;
    foto: string;
    status: string = '1';
    
}

export class UsuarioSistema {
    id: number=0;
    nome: string ;
    email: string;
    senha: string;
}

export class Reserva {
    id: number=0;
    area: String;
    nome: string ;
    descricao: string;
    dataHoraReserva: Date ;
}

export class Sindicalizado {
    id: number=0;
    nome: string ;
    email: string;
    senha: string;
    cpf: string ;
    rg: string;
    foto: string;
    dataNascimento: string ;
    dataValidadeCarteira: string;
    dataHoraUltimoAcesso: string;
    dataHoraCadastro: string ;
    endereco: string;
    telefone: string;
    origemCadastro: string;
    sindicalizado: string;
    validado: string;
    banco:string;
    agencia:string;
              
}