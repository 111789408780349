<div class="container">
    <div class="grid">
  
      <div class="col-12">
        <h1>Convênios</h1>
      </div>
  
      <div class="col-12 p-fluid">
        <label>Nome</label>
        <input pInputText type="text" #nome>
      </div>
  
      
      <div class="col-12">
        <p></p>
        <button pButton label="Pesquisar" (click)="pesquisarPorNome(nome.value)"></button>
      </div>
    </div>
  
    <div class="grid">
      <div class="col-12">
        <p-table [value]="convenios" [paginator]="true" [rows]="10" [responsive]="true"  >
          <ng-template pTemplate="header">
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>Título</th>
              <th class="col-acoes"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-convenio>
            <tr>
              <td>
                <span class="p-column-title">Código</span>
                {{ convenio.id }}
              </td>
  
              <td>
                <span class="p-column-title">Descrição</span>
                {{ convenio.nome }}
              </td>

              <td>
                <span class="p-column-title">Resumo</span>
                {{ convenio.titulo }}
              </td>
  

 
              <td class="col-acoes">
                <a pButton icon="pi pi-pencil" pTooltip="Editar" tooltipPosition="top" (click)="detalhe(convenio.id)"></a>
                <button  pButton icon="pi pi-trash" pTooltip="Excluir" tooltipPosition="top"
                (click)="excluirPorId(convenio.id)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p></p>
    <a pButton label="Novo Convênio" routerLink="/convenios/novo"></a>
  
  </div>
