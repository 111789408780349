import { Component, OnInit } from '@angular/core';
import { ReservaServiceService } from '../reserva-service.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reserva-pesquisa',
  templateUrl: './reserva-pesquisa.component.html',
  styleUrls: ['./reserva-pesquisa.component.css']
})
export class ReservaPesquisaComponent {

  constructor(private reservaServiceService: ReservaServiceService,
    private confirmationService: ConfirmationService,
    private route : Router) {
    ; 
    }
  dados: any = []

  ngOnInit() {

    this.reservaServiceService.pesquisar()
      .then((response) => this.dados = response);

  }

  pesquisarPorNome(nome: string) {

    if (nome === "") {
      this.reservaServiceService.pesquisar()
        .then((response) => this.dados = response);
    }
    else {
      this.reservaServiceService.pesquisarPorNome(nome)
        .then((response) => this.dados = response);
    }
  }

  excluirPorId(id: string){

    this.confirmationService.confirm({
      message: 'Tem certeza que você deseja excluir esse registro ?',
      accept: () => {

        this.reservaServiceService.excluirPorId(id)
        .then(()=> {this.pesquisarPorNome("")});
      }
    }    
    );
  }

  alterarPorId(id: string){
        this.reservaServiceService.excluirPorId(id)
        .then(()=> {this.pesquisarPorNome("")});
  }

  detalhe(id: string){
    this.route.navigate(['/reservas/'+id])
    
}

}

