
<div class="container">
    <form #f="ngForm">
        <div class="grid">
            <div class="col-12">
                <h1>{{editando ? 'Editando ' : 'Novo '}}Sindicalizado</h1>
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
               
                <input type="checkbox" id="checkboxsindicalizado" [checked]="dados.sindicalizado === 'S'" (change)="onChangeSindicalizado($event)">
                <label for="checkbox">Sindicalizado</label>                
                
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
               

                <input type="checkbox" id="checkboxvalidado" [checked]="dados.validado === 'S'" (change)="onChangeValidado($event)">
                <label for="checkbox">Validado</label>

                
                
            </div>  
            <p></p>

            <div class="Foto">
                <img [src]="dados.foto" class="fotoindentidade" loading="lazy">
                <div class="col-12">
                    <input type="file" accept=".jpg" name="foto" #foto="ngModel" [(ngModel)]="dados.foto" (change)="onFileSelected($event)">
                </div>
            </div>
            <p></p>


            <label>Banco</label>       
            <div class="col-12 md:col-9">
                
                <div class="input-container">
                    <p-radioButton name="Sindic" value="1" [(ngModel)]="dados.banco" inputId="banco1" label="Banco do Brasil"></p-radioButton>
                                    
                    <p-radioButton name="Sindic" value="2" [(ngModel)]="dados.banco" inputId="banco2" label= "Banco do Nordeste do Brasil"></p-radioButton>
                    
                    <p-radioButton name="Sindic" value="3" [(ngModel)]="dados.banco" inputId="banco3" label="Bradesco"></p-radioButton>
                                    
                    <p-radioButton name="Sindic" value="4" [(ngModel)]="dados.banco" inputId="banco4" label ="Caixa Econômica Federal"></p-radioButton>
         
                    <p-radioButton name="Sindic" value="5" [(ngModel)]="dados.banco" inputId="banco5" label="Itaú"></p-radioButton>
                                    
                    <p-radioButton name="Sindic" value="6" [(ngModel)]="dados.banco" inputId="banco6" label="Santander"></p-radioButton>
                    



                </div>
                
            </div>
   
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Agência</label>
                <input pInputText type="text" name="agencia" [(ngModel)]="dados.agencia" #nome="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Nome</label>
                <input pInputText type="text" name="nome" [(ngModel)]="dados.nome" #nome="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Email</label>
                <input pInputText type="email" name="email" [(ngModel)]="dados.email" #email="ngModel">
            </div>
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Senha</label>
                <input pInputText type="password" name="senha" [(ngModel)]="dados.senha" #senha="ngModel">
            </div>
          
            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>CPF</label>
                <input pInputText type="text" name="cpf" [(ngModel)]="dados.cpf" #cpf="ngModel">
            </div>
            <p></p>
                <div class="col-12 md:col-9 p-fluid">
                <label>RG</label>
                <input pInputText type="text" name="rg" [(ngModel)]="dados.rg" #rg="ngModel">
            </div>

            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Data de Nascimento</label>
                <input pInputText type="text" name="dataNascimento" [(ngModel)]="dados.dataNascimento" #dataNascimento="ngModel">
            </div>
            <p></p>
                <div class="col-12 md:col-9 p-fluid">
                <label>Validade da Carteira</label>
                <input pInputText type="text" name="dataValidadeCarteira" [(ngModel)]="dados.dataValidadeCarteira" #dataValidadeCarteira="ngModel">
            </div>

            <p></p>
            <div class="col-12 md:col-9 p-fluid">
                <label>Telefone</label>
                <input pInputText type="text" name="telefone" [(ngModel)]="dados.telefone" #telefone="ngModel">
            </div>
            <p></p>
                <div class="col-12 md:col-9 p-fluid">
                <label>Origem</label>
                <input pInputText readonly type="text" name="origemCadastro" [(ngModel)]="dados.origemCadastro" #origemCadastro="ngModel">
            </div>            
          
            <p></p>

            <div class="col-12">
                <button pButton label="Salvar" (click)="salvar()"></button>
                <!-- <button pButton type="button" label="Novo" class="p-button-info"></button> -->
                <a routerLink="/sindicalizados">Voltar para a pesquisa</a>
            </div>
        </div>

    </form>
</div>


