import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UsuarioServiceService } from '../usuario-service.service';

@Component({
  selector: 'app-usuarios-excluidos',
  templateUrl: './usuarios-excluidos.component.html',
  styleUrls: ['./usuarios-excluidos.component.css']
})
export class UsuariosExcluidosComponent implements OnInit {

  constructor(private usuarioServiceService: UsuarioServiceService,
    private confirmationService: ConfirmationService,
    private route : Router) {
    ; 
    }

  dados: any = []

  ngOnInit() {

    this.usuarioServiceService.pesquisarExcluidos()
      .then((response) => this.dados = response);

  }

  pesquisarPorNome(nome: string) {

    if (nome === "") {
      this.usuarioServiceService.pesquisarExcluidos()
        .then((response) => this.dados = response);
    }
    else {
      this.usuarioServiceService.pesquisarPorNomeExcluidos(nome)
        .then((response) => this.dados = response);
    }
  }

  desexcluirPorId(id: string){
   
      this.confirmationService.confirm({
        message: 'Tem certeza que você deseja RESTAURAR esse registro ?',
        accept: () => {

          this.usuarioServiceService.desexcluirPorId(id)
          .then(()=> {this.pesquisarPorNome("")});
        }
      }    
      )
    }
    
  

  alterarPorId(id: string){
        this.usuarioServiceService.excluirPorId(id)
        .then(()=> {this.pesquisarPorNome("")});
  }

  detalhe(id: string){
    this.route.navigate(['/sindicalizados/'+id])
    
}

}